const ResumeApi = [
  {
    id: 12,
    category: "experience",
    year: "Freelance (2024)",
    title: "Développeur web et mobile",
    desc: "Conception et développement de la suite  IIZIPRO, incluant un site e-commerce, des applications mobiles (e-commerce, point de vente, statistiques en temps réel) et des modèles personnalisables. Intégration d’outils d’analyse pour le suivi des ventes, des stocks et des projections financières..",
    desc2:"",
    rate: "",
  },{
    id: 11,
    category: "experience",
    year: "Consultant full stack Croix Bleue (2024)",
    title: "Développeur web et mobile",
    desc: "Conception et développement d'une application mobile Flutter déployée sur Android, iOS et Web, permettant aux clients d’acheter ou de modifier leurs contrats d’assurance voyage. Mise en place d’une architecture robuste pour les tests automatisés (unitaires, intégration, fonctionnels) et des pipelines CI/CD L’équipe était responsable de l’ensemble du processus de développement (frontend, backend, intégration continue).",
    desc2:"",
    rate: "",
  },{
    id: 10,
    category: "experience",
    year: "Consultant .NET - Mckesson GROUP INC (2023) ",
    title: "Développeur web et mobile",
    desc: "Développement et évolution de la partie frontend d'une application mobile dédiée à la gestion de la réception des marchandises pour McKesson.  Conception d’interfaces utilisateurs intuitives et performantes en utilisant Xamarin.Forms et le modèle MVVM. Optimisation de l’expérience utilisateur et intégrat",
    desc2:"",
    rate: "",
  },{
    id: 9,
    category: "experience",
    year: "Stage rémunéré APT-SYS MALLYANCE (2023) ",
    title: "Développeur web et mobile",
    desc: "Rattaché au responsable technique la mission consiste à développer une application innovante pour la gestion des candidats pour une agence d'intérim spécialisé dans la santé.",
    desc2:"",
    rate: "",
  },{
    id: 8,
    category: "experience",
    year: "Développeur freelance (2020 - 2022) ",
    title: "Développeur web et mobile",
    desc: "Conception & vente d'application web et mobile.",
    desc2:"",
    rate: "",
  },{
    id: 7,
    category: "experience",
    year: "Développeur freelance MARTINIQUE ECO DESING (2018 - 2019) ",
    title: "Développeur web et mobile ",
    desc: "Développement d’une application mobile de Gestionnaire de frais généraux pour une boutique de prêt à porter",
    desc2:"",
    rate: "",
  },
  {
    id: 6,
    category: "experience",
    year: "Wipit (2011 - 2018) ",
    title: "Ingénieur Systèmes & réseaux ",
    desc: "Ingénieur Systèmes et réseaux, Ingénieur sécurité, sauvegarde.",
    desc2:"",
    rate: "",
  },
  {
    id: 5,
    category: "experience",
    year: "PI-Service (2005 - 2010) ",
    title: "Administrateur systèmes & réseaux",
    desc: "Administrateur Systèmes et réseaux, pour l'exploitation bureautique des technologies Microsoft Office.",
    desc2:"",
    rate: "",
  },
  {
    id: 4,
    category: "education",
    year: "STUDI (2022-2024)",
    title: "BACHELOR Fullstack OPTION Python ",
    desc: "Equivalence canadienne : BACCALAUREAT",
    desc2:"Certifications professionnelles RNCP36146",
    rate: "Nomenclature du niveau de qualification : Niveau 6 ",
  },
  {
    id: 3,
    category: "education",
    year: "STUDI (2022)",
    title: "Graduate Fullstack OPTION Flutter ",
    desc: "Équivalence canadienne : DEC",
    desc2:" Certifications professionnelles RNCP31114",
    rate: "Nomenclature du niveau de qualification : Niveau 5 ",
  },
  {
    id: 2,
    category: "education",
    year: "CNED  (2003 - 2005) ",
    title: "BTS Informatique de Gestion",
    desc: "Équivalence canadienne : DEC",
    desc2:"",
    rate: "",
  },
    {
      id: 1,
      category: "education",
      year: "UNIVERSITE DE GRENOBLE 1 (2001-2002) ",
      title: "DAEU B ",
      desc: "Équivalence canadienne : DEC ",
      desc2:"",
      rate: "Equivalent baccalauréat Scientifique en france.",
    },

 
   
   
 

  ]
  
  export default ResumeApi