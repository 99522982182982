const Portfolio_data2 = [
   
    {
      id: 1,
      category: "Easytodo",
      totalLike: "600",
      title: "La ToDoList intelligente",
      image: "./image/easytodo.png",
      description: "Mobile",
      commentaire: "Dart, Flutter, github, Figma",
      adresseGithub:
        "https://github.com/NOA-FASHION/IcanDoIt/tree/master/icandoit",
      adresseWeb: "https://noa-fashion.github.io/easytodo/",
    },
    {
      id: 2,
      category: "Sport-trainning",
      totalLike: "630",
      title: "gestionnaire d'accès",
      image: "./image/sport-trining.png",
      description: "Web",
      commentaire: "Symfony, html/css, Postgress, Php ",
      adresseGithub : "https://github.com/NOA-FASHION/sport_training",
      adresseWeb: "https://backend-strapi.online/sport-training/"
    },
    {
      id: 3,
      category: "SiteEcommerce",
      totalLike: "750",
      title: "Site Ecommerce",
      image: "./image/ecommercePhp.png",
      description: "Web ",
      commentaire: "Symfony, html/css, Postgress, Php",
      adresseGithub: "https://github.com/malmont/EcommerceSymfony",
      adresseWeb: "https://backend-strapi.online/jeesign/",
    },
  ]
  export default Portfolio_data2